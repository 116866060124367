import React from "react"
import { Row, Col, Spinner } from "react-bootstrap"
import "./content-box.css"



const ContentBox = (props) => {
    const DynamicContent = (props) => {
      if(props.loading){
        return (
        <Spinner className={props.className} animation="border" role="status">
          <span className="sr-only">Cargando...</span>
        </Spinner>)
      }else{
        if(!props.content){
          return <Col className="align-self-center" md={12}>¡Tenemos información muy importante para ti!</Col>
        }else{
          return <div className="content-div" dangerouslySetInnerHTML={{ __html: props.content }}></div>
        }
      }
    }
    return (     
        <Col md={5} sm={12} xs={12} lg={5} className="d-flex" style={{backgroundColor: 'white'}}  >
          <Row>
              <Col md={12} sm={12} xs={12} >
                  <DynamicContent {...props} />
              </Col>
          </Row>
        </Col>
      )
}

export default ContentBox;