import React from "react"

import FlagPeruSVG from '../images/pe.svg'

const FlagImage = (props) => {
//   const data = useStaticQuery(graphql`
//   query {
//     images: allFile {
//       edges {
//         node {
//           relativePath
//           name
//           childImageSharp {
//             sizes(maxWidth: 22) {
//               ...GatsbyImageSharpSizes
//             }
//           }
//         }
//       }
//     }
//   }
// `);
  
  return (
    <>
      <img alt="Per." src={FlagPeruSVG} className="img-responsive" width="22"></img>&nbsp;Per.&nbsp;<i className="fa fa-chevron-down"></i>
    </>
  );
}

export default FlagImage
