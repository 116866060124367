import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import { useState, useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row, Alert, FormGroup } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import ReCAPTCHA from "react-google-recaptcha";

import ListDivision from '../list-division'

import FlagImage from '../flagimage'
import ResultadoModal from '../resultado-modal/resultado-modal'
import InformacionSeguridadModal from '../informacionseguridad-modal/informacionseguridad-modal'
import './inscripcion-form.css'
//import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';


registerLocale('es', es)
setDefaultLocale('es');
const divisionesQuery = gql`
query {
    divisiones(pais: "PE") {
      id,
      nombre,
      tipo,
      padre,
      pais
    }
  }
`;

const OBTENER_GENEROS = gql`
query{
	referencias(tipo:"GENERO"){
		id,
		nombre,
		valor
	}
  }
`;

const OBTENER_TIPOS_DOCUMENTOS = gql`
query{
	referencias(tipo:"TIPO_DOCUMENTO"){
	  id,
	  nombre,
	  valor
	}
  }
`;


const InscripcionForm =
	forwardRef(({ errors,
		handleBlur,
		handleChange,
		handleSubmit,
		touched,
		handleChangeField,
		values,
		loadingSubmit,
		errorSubmit,
		dataSubmit,
		handleBlurEmail,
		invalid,
		mensajeExito,
	}, ref) => {
		const { loading: loadingGeneros, data: dataGeneros } = useQuery(OBTENER_GENEROS);
		const { loading: loadingTipos, data: dataTipos } = useQuery(OBTENER_TIPOS_DOCUMENTOS);
		const { loading, data: { divisiones } = [] } = useQuery(divisionesQuery);
		const [loadInitData, setLoadInitData] = useState(false);
		const [showResultado, setShowResultado] = useState({
			type: 'error',
			show: false,
			text: ''
		});

		const [showTerminos, setShowTerminos] = useState({
			type: '',
			show: false,
		});

		const [enableRecaptcha, setEnableRecaptcha] = useState(true);

		useEffect(() => {
			console.log('Recaptcha', process.env.GATSBY_RECAPTCHA_ENABLED === 'true');
			setEnableRecaptcha(process.env.GATSBY_RECAPTCHA_ENABLED === 'true');
		}, [setEnableRecaptcha])

		const recaptcha = useRef(null)
		useImperativeHandle(ref, () => ({
			resetForm() {
				if (enableRecaptcha) {
					recaptcha.current.reset();
				}
			}
		}));



		useEffect(() => {
			if (loadingSubmit === false && errorSubmit) {
				let errorData = errorSubmit;
				if (errorSubmit.graphQLErrors) {
					errorData = errorSubmit.graphQLErrors[0];
				}
				setShowResultado({
					type: 'error',
					show: true,
					text: errorData.message,
					title: errorData.extensions ? errorData.extensions.code : 'El registro de tu solicitud ha presentado errores'
				});
			}
			if (loadingSubmit === false && dataSubmit) {
				setShowResultado({
					type: 'success',
					show: true,
					text: mensajeExito ?? 'Muy pronto te contactaremos',
				});
			}
		}, [mensajeExito, loadingSubmit, errorSubmit, dataSubmit, setShowResultado])

		const [tiposDocumentos, setTiposDocumentos] = useState([]);
		const [departamentos, setDepartamentos] = useState([]);
		const [generos, setGeneros] = useState([]);
		const [provincias, setProvincias] = useState([]);
		const [distritos, setDistritos] = useState([]);
		//const today = new Date();

		useEffect(() => {
			if (loadingTipos === false && dataTipos) {
				setTiposDocumentos(dataTipos.referencias);
			}
		}, [loadingTipos, dataTipos, setTiposDocumentos])

		useEffect(() => {
			if (loadingGeneros === false && dataGeneros) {
				setGeneros(dataGeneros.referencias);
			}
		}, [loadingGeneros, dataGeneros, setGeneros])

		useEffect(() => {
			const getDivisiones = () => {
				if (!loading && divisiones) {
					const departamentosData = divisiones.filter(division => division.tipo === 'DEPARTAMENTO');
					setDepartamentos(departamentosData);
					setLoadInitData(false);
				}
			}

			setLoadInitData(true)
			getDivisiones();

		}, [loading, divisiones, setLoadInitData, loadInitData])



		const changeDepartamento = (selected) => {
			let provincias = []
			if (selected.length >= 1) {
				if (!loading && divisiones) {
					provincias = divisiones.filter(division => division.tipo === 'PROVINCIA'
						&& division.padre === selected[0].id);
					setProvincias(provincias);
					handleChangeField('departamento', selected[0].nombre)
				}
			}
		}

		const changeProvincia = (selected) => {
			let distritos = [];
			if (selected.length >= 1) {
				if (!loading && divisiones) {
					distritos = divisiones.filter(division => division.tipo === 'DISTRITO'
						&& division.padre === selected[0].id);
					setDistritos(distritos);
					handleChangeField('provincia', selected[0].nombre)
				}
			}
		}


		const changeDistrito = (selected) => {

			if (selected.length >= 1) {
				handleChangeField('distrito', selected[0].nombre)
			}
		}

		const handleClose = () => {
			setShowResultado(false)
		}


		const OpcionesReferencias = ({ data, placeholder }) => {
			return (
				<>
					<option value=''>{placeholder}</option>
					{
						data.map(option => {
							return <option key={option.id} value={option.valor}>{option.nombre}</option>
						})
					}
				</>
			)
		};

		const _onFocus = (e) => {
			console.log('_onFocus', e.target.value);
			//e.target.placeholder = 'dd/mm/aaaa';
		};


		const _onBlurDate = (e) => {
			console.log('_onBlurDate', e.target.value);
			handleBlur(e);
		};

		const _onChangeDate = (name, value) => {
			let date = undefined;
			console.log('_onChangeDate', name, value);
			if (value) {
				date = new Date(value);
			}
			handleChangeField(name, date);
		};

		const handleBlurCheckbox = (e) => {
			e.target.value = e.target.checked ? 'S' : '';
			handleBlur(e);
		}

		const handleChangeCheckbox = (e) => {
			handleChangeField('aceptacionTerminosyPolitica', e.target.checked ? 'S' : '');
		}

		const handleRecaptcha = (value) => {
			handleChangeField('validacionSeguridad', value);
		}

		
		return (
			<>
				<Form noValidate onSubmit={handleSubmit}>
					<div>
						<Row>
							<Col md={6} sm={12} className="no-padding-right">
								<Form.Group>
									<Form.Control type="text" placeholder="Nombres" name="nombres" id="nombres"
										maxLength={40}
										isInvalid={touched.nombres && !!errors.nombres}
										className="input-square" required
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.nombres}
									/>
									<Form.Control.Feedback type="invalid" tooltip>
										{touched.nombres && errors.nombres}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6} sm={12} className="no-padding-left">
								<Form.Group>
									<Form.Control type="text" placeholder="Apellidos" name="apellidos" id="apellidos" maxLength={40}
										className="input-square" required
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={touched.apellidos && !!errors.apellidos}
										value={values.apellidos}
									/>
									<Form.Control.Feedback type="invalid" tooltip>
										{errors.apellidos}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6} sm={12} className="no-padding-right" >
								<Form.Group className="form-group">
									<InputGroup className="mb-3">
										<Button variant="metal" ><FlagImage></FlagImage></Button>
										<Form.Control type="tel" name="celular" id="celular" className="input-square"
											placeholder="Celular"
											minLength="9" maxLength="9" required
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={touched.celular && !!errors.celular}
											value={values.celular}
										/>
										<Form.Control.Feedback type="invalid" tooltip>
											{touched.celular && errors.celular}
										</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={6} sm={12} className="no-padding-left" >
								<Form.Group className="form-group">
									<Form.Control type="email" placeholder="Email personal" name="correoElectronico" id="correoElectronico" maxLength={80}
										className="input-square"
										onChange={handleChange}
										onBlur={handleBlurEmail}
										isInvalid={touched.correoElectronico && !!errors.correoElectronico}
										value={values.correoElectronico}
									/>
									<Form.Control.Feedback type="invalid" tooltip>
										{errors.correoElectronico}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col md={6} sm={12} className="no-padding-right" >
								<Form.Group className="form-group">
									<Form.Control as="select"
										id="tipoIdentificacion"
										name="tipoIdentificacion"
										onChange={handleChange}
										isInvalid={touched.tipoIdentificacion && !!errors.tipoIdentificacion}
										value={values.tipoIdentificacion}
										onBlur={handleBlur}
									>
										<OpcionesReferencias data={tiposDocumentos} selected={values.tipoIdentificacion}
											placeholder="Tipo de Documento" >
										</OpcionesReferencias>
									</Form.Control>
									<Form.Control.Feedback type="invalid" tooltip>
										{touched.tipoIdentificacion && errors.tipoIdentificacion}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6} sm={12} className="no-padding-left" >
								<Form.Group className="form-group">
									<Form.Control type="text" placeholder="# de documento" name="numeroIdentificacion"
										id="numeroIdentificacion" maxLength={15} className="input-square" required
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.numeroIdentificacion}
										isInvalid={touched.numeroIdentificacion && !!errors.numeroIdentificacion}
									/>
									<Form.Control.Feedback type="invalid" tooltip>
										{touched.numeroIdentificacion && errors.numeroIdentificacion}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col md={6} sm={12} className="no-padding-right" >
								<FormGroup className="form-group">
									Fecha expedición documento																									
									<DatePicker type="date" placeholderText="Fecha expedición documento" name="fechaEmision"
										id="fechaEmision" maxLength={10} className="input-square form-control" required
										dayPlaceholder="DD"
										monthPlaceholder="MM"
										yearPlaceholder="AAAA"
										locale="es-CO"
										format="dd/MM/yyyy"
										disabledKeyboardNavigation
										showLeadingZeros={true}
										showMonthDropdown
										showYearDropdown																			
										onChange={value => _onChangeDate('fechaEmision', value)}
										//onKeyDown={_onDateTyping} 
										// onBlur={(e) => {
										// 	//e.target.placeholder = 'Fecha expedición documento';
										// 	_onBlurDate(e);
										// }}
										onFocus={_onFocus}										
										//maxDate={today}
										selected={values.fechaEmision}
										value={values.fechaEmision}
									/>
									<Alert show={errors.fechaEmision !== undefined && errors.fechaEmision.trim() !== ''} variant="invalid">
										{errors.fechaEmision}
									</Alert>
								</FormGroup>
							</Col>
							<Col md={6} sm={12} className="no-padding-left" >
								<Form.Group className="form-group">
									Fecha vencimiento documento
									<DatePicker type="date" placeholderText="Fecha vencimiento documento" name="fechaCaducidad"
										id="fechaCaducidad" maxLength={10} className="input-square form-control" required
										dayPlaceholder="DD"
										monthPlaceholder="MM"
										yearPlaceholder="AAAA"
										locale="es-CO"
										format="dd/MM/yyyy"
										disabledKeyboardNavigation
										showLeadingZeros={true}
										showMonthDropdown
										showYearDropdown
										//onKeyDown={_onDateTyping}
										onChange={value => _onChangeDate('fechaCaducidad', value)}
										onBlur={(e) => {
											//e.target.placeholder = 'Fecha vencimiento documento';
											_onBlurDate(e);
										}}
										onFocus={_onFocus}
										selected={values.fechaCaducidad}
										value={values.fechaCaducidad}
									/>
									<Alert show={errors.fechaCaducidad !== undefined && errors.fechaCaducidad.trim() !== ''} variant="invalid">
										{errors.fechaCaducidad}
									</Alert>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col md={6} sm={12} className="no-padding-right" >
								<Form.Group className="form-group">
									Fecha de Nacimiento
									<DatePicker type="date" placeholderText="Fecha de Nacimiento" name="nacimiento"
										id="nacimiento" maxLength={10} className="input-square form-control" required
										dayPlaceholder="DD"
										monthPlaceholder="MM"
										yearPlaceholder="AAAA"
										locale="es-CO"
										format="dd/MM/yyyy"
										showMonthDropdown
										showYearDropdown
										disabledKeyboardNavigation
										//onKeyDown={_onDateTyping}
										onChange={value => _onChangeDate('nacimiento', value)}
										onBlur={(e) => {
											//e.target.placeholder = 'Fecha de Nacimiento';
											_onBlurDate(e);
										}}
										onFocus={_onFocus}
										//maxDate={today}
										selected={values.nacimiento}
										value={values.nacimiento}
									/>
									<Alert show={errors.nacimiento !== undefined && errors.nacimiento.trim() !== ''} variant="invalid">
										{errors.nacimiento}
									</Alert>
								</Form.Group>
							</Col>
							<Col md={6} sm={12} className="no-padding-left" >
								<Form.Group className="form-group">
									&nbsp;
									<Form.Control as="select"
										id="genero"
										name="genero"
										placeholder="Género"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.genero}
										isInvalid={touched.genero && !!errors.genero}
									>
										<OpcionesReferencias
											data={generos}
											loading={loadingGeneros}
											placeholder="Género"
										/>
									</Form.Control>
									<Form.Control.Feedback type="invalid" tooltip>
										{touched.genero && errors.genero}
									</Form.Control.Feedback>
								</Form.Group>

							</Col>
						</Row>

						<Row>
							<Col md={4} sm={12} className="no-padding-right" >
								<Form.Group className="form-group">
									<ListDivision id="departamento" placeholder="Departamento"
										name="departamento"
										isInvalid={touched.departamento && !!errors.departamento}
										errors={errors.departamento}
										onInputChange={(text, e) => handleChange(e)}
										onBlur={(e) => { handleBlur(e) }}
										data={departamentos} onChange={changeDepartamento}
										touched={touched.departamento}
										selectedValue={values.departamento}
									></ListDivision>
								</Form.Group>
							</Col>
							<Col md={4} sm={12} className="no-padding-left no-padding-right" >
								<Form.Group className="form-group">
									<ListDivision id="provincia"
										name="provincia"
										placeholder="Provincia"
										isInvalid={touched.provincia && !!errors.provincia}
										data={provincias}
										errors={errors.provincia}
										onInputChange={(text, e) => handleChange(e)}
										selectedValue={values.provincia}
										onBlur={(e) => { handleBlur(e) }}
										onChange={changeProvincia} ></ListDivision>
								</Form.Group>
							</Col>
							<Col md={4} sm={12} className="no-padding-left" >
								<Form.Group className="form-group">
									<ListDivision id="distrito" placeholder="Distrito"
										data={distritos}
										name="distrito"
										isInvalid={touched.distrito && !!errors.distrito}
										errors={errors.distrito}
										onInputChange={(text, e) => {
											handleChange(e);
										}}
										onBlur={(e) => { handleBlur(e) }}
										selectedValue={values.distrito}
										touched={touched.distrito}
										onChange={changeDistrito} ></ListDivision>
								</Form.Group>
							</Col>
						</Row>


						<Row>
							<Col md={6} sm={12} className="no-padding-right" >
								<Form.Group className="form-group">
									<Form.Control type="text" placeholder="Otro (Poder Judicial, Urbanización, Centro poblado)" name="otra"
										id="otra" maxLength={40} className="input-square"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.otra}
									/>
								</Form.Group>
							</Col>
							<Col md={6} sm={12} className="no-padding-left" >
								<Form.Group className="form-group">
									<Form.Control type="text" placeholder="Dirección" name="direccion"
										id="direccion" maxLength={40} className="input-square" required
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.direccion}
										isInvalid={touched.direccion && !!errors.direccion}
									/>
									<Form.Control.Feedback type="invalid" tooltip>
										{touched.direccion && errors.direccion}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col md={6} sm={12} className="no-padding-right" >
								<Form.Group className="form-group">
									<Form.Control type="text" placeholder="Complemento" name="complemento"
										value={values.complemento}
										onChange={handleChange}
										onBlur={handleBlur}
										id="complemento" maxLength={40} className="input-square" />
								</Form.Group>
							</Col>
							<Col md={6} sm={12} className="no-padding-left" >
								<Form.Group className="form-group">
									<Form.Control type="tel" placeholder="Teléfono Adicional" name="telefonoAdicional"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.telefonoAdicional}
										isInvalid={touched.telefonoAdicional && !!errors.telefonoAdicional}
										id="telefonoAdicional" maxLength={10} className="input-square" />
									<Form.Control.Feedback type="invalid" tooltip>
										{touched.telefonoAdicional && errors.telefonoAdicional}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={12} sm={12} >
								<Form.Group className="form-group m-form__group mx-auto mt-3 m--content-agreement">
									<Form.Label >
										<Form.Check custom type="checkbox"
											id="aceptacionTerminosyPolitica"
										>
											<Form.Check.Input type="checkbox"
												onChange={handleChangeCheckbox}
												onBlur={handleBlurCheckbox}
												isInvalid={touched.aceptacionTerminosyPolitica && !!errors.aceptacionTerminosyPolitica}
												name="aceptacionTerminosyPolitica"
												defaultChecked={false}
												value={values.aceptacionTerminosyPolitica}
											/>
											<Form.Check.Label>Autorizo y acepto la finalidad del tratamiento de mis datos personales definida en los <button type="button" className="btn btn-link" onClick={() => { setShowTerminos({ show: true, type: 'terminos' }) }} >Términos y Condiciones</button> y la <button type="button" className="btn btn-link" onClick={() => { setShowTerminos({ show: true, type: 'privacidad' }) }}>Política de Privacidad y Seguridad</button>.</Form.Check.Label>
											<Form.Control.Feedback type="invalid" tooltip>
												Debes aceptar la politica de privacidad antes de realizar el registro.
											</Form.Control.Feedback>
										</Form.Check>
									</Form.Label>
								</Form.Group>
							</Col>
						</Row>
						{enableRecaptcha ? <Row className="d-flex">
							<Col md={12} sm={12} className="d-flex mb-4 mr-auto ml-auto"  >
								<ReCAPTCHA ref={recaptcha} className="mr-auto ml-auto" sitekey={process.env.GATSBY_RECAPTCHA_KEY}
									onChange={handleRecaptcha}
									onExpired={() => { handleChangeField('validacionSeguridad', null) }} />
							</Col>
							<Alert show={errors.validacionSeguridad !== undefined && errors.validacionSeguridad.trim() !== ''} variant="invalid">
								{errors.validacionSeguridad}
							</Alert>
						</Row> : <></> }
						<Row className="d-flex">
							<Col md={12} sm={12} className="d-flex mb-4 mr-auto ml-auto" >
								<Button className="mr-auto ml-auto" type="submit" variant="primary" disabled={invalid || loadingSubmit || loadInitData} >
									Registrarme</Button>
							</Col>
						</Row>
					</div>
				</Form>
				<ResultadoModal title={showResultado.title} type={showResultado.type} show={showResultado.show} onHide={handleClose} >
					{showResultado.text}
				</ResultadoModal>
				<InformacionSeguridadModal type={showTerminos.type} show={showTerminos.show} onHide={() => setShowTerminos({ show: false })}></InformacionSeguridadModal>
			</>
		)
	});

export default InscripcionForm;

	// const styles = {
	// 	recaptchaTextarea: {
	// 		"width": "250px", 
	// 		"height": "40px",
	// 		"border": "1px solid rgb(193, 193, 193)",
	// 		"margin": "10px 25px",
	// 		"padding": "0px",
	// 		"resize": "none",
	// 		"display": "none"
	// 	},
	// 	hidden: {
	// 		display: "none"
	// 	}

	// }