import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './informacionseguridad-modal.css';



const InformacionSeguridadModal = (props) => {
    
    const textosDocumentos = useStaticQuery(graphql`query getDocContent{
        allDocx {
            edges {
              node {
                content
                name
              }
            }
          }
        }`);

    let textAreaContent = '';
    if(props.type){
        const contenido = textosDocumentos.allDocx.edges.find((edge) => edge.node.name === props.type)
        if(contenido.node.content){
            textAreaContent = contenido.node.content;    
        }
    }            

    const [content, setContent] = useState(textAreaContent);
    const [notReaded, setNotReaded] = useState(true);
    const offset = '300';

    useEffect(() => {
        setContent(textAreaContent);
        setNotReaded(true);
    }, [setContent, setNotReaded, textAreaContent])



    
    let title = 'Política de Privacidad y Seguridad';
    if(props.type === 'terminos'){
        title = 'Términos y Condiciones';
    }

    const calculateBottom = (e) => {
        const stillDisable = e.target.scrollHeight > e.target.scrollTop + (offset*1);
        setNotReaded(stillDisable);
    }

    const handleClose = () => {        
        setNotReaded(true);
        props.onHide();
    }

    return (
        <Modal {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='informacion-modal'
        centered>
        <Modal.Header>
            <Modal.Title className="terminos-modal">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{height: offset + 'px', overflowY: 'scroll'}} onScroll={calculateBottom}
            dangerouslySetInnerHTML={{__html: content}} >
        </Modal.Body>

        <Modal.Footer>
            <Button variant="message-close" onClick={handleClose} disabled={notReaded} >Acepto</Button>
        </Modal.Footer>
    </Modal>
    )
}

export default InformacionSeguridadModal;