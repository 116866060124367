import React, { useEffect, useState } from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ContentBox from "../components/content-box/content-box"
import FormBox from "../components/form-box"
import { Row } from "react-bootstrap"
import { gql , useLazyQuery } from "@apollo/client"
import packageJson from '/package.json';

console.log(`Application Version ${packageJson.version}`);

const enableRecaptcha = process.env.GATSBY_RECAPTCHA_ENABLED === 'true';
console.log('En Recaptcha', enableRecaptcha);

const CONTENIDO_QUERY = gql`
query{
    referencias(tipo:"CONTENIDO"){
        nombre,
        valor
    }
}
`;

const IndexPage = (props) => {
  const [bgImagen, setbgImagen] = useState('')
  const [boxContent, setBoxContent] = useState('')
  const [urlWsp, setUrlWsp] = useState('')
  const [loadContenido, {loading, data}] = useLazyQuery(CONTENIDO_QUERY, {
    onCompleted : (data) => {
        
        const content = data.referencias.filter(referencia => referencia.nombre === 'TEXTO_BENEFICIOS')
        setBoxContent(content[0].valor)
    }
  });

  useEffect(() => {
    loadContenido();    
  }, [loadContenido])

  let imagen = '';
  if(!loading && data){
    if(bgImagen === ''){
      imagen = data.referencias.filter(referencia => referencia.nombre === 'IMAGEN_FONDO')
      setbgImagen(imagen[0].valor)
    }     
    if(urlWsp === ''){
      const content = data.referencias.filter(referencia => referencia.nombre === 'URL_CHAT')
      setUrlWsp(content[0].valor)
    }
  }

  const initialValues = {
		nombres: '', apellidos: '', celular: '',
		correoElectronico: '',	tipoIdentificacion: '',
		numeroIdentificacion: '', fechaEmision: '',
		fechaCaducidad: '', nacimiento: '', genero: '',
		departamento: '', provincia: '', distrito: '',
		otra: '', direccion: '', 
    complemento: '',
    telefonoAdicional: '',
		aceptacionTerminosyPolitica: '',
		validacionSeguridad: '',
    verificaEmail: 'N'
	}


  const nameValidation = (fieldName, fieldValue) =>{
		if (fieldValue.trim() === '') {
			return `El campo ${fieldName} es requerido`;
		  }
		  if (/[^a-zA-ZÁÉÍÓÚáéíóúñÑ -]/.test(fieldValue)) {
			return `Caracteres inválidos en ${fieldName}`;
		  }
		  
		  return null;
	}

  const phoneValidation = (fieldName, fieldValue, size=10, required=true) =>{
		if (required && fieldValue.trim() === '') {
			  return `El campo ${fieldName} es requerido`;
		}      
    if(fieldValue.trim() !== ''){
      if(fieldValue.length !== size){
        return `${fieldName} debe tener un tamaño de ${size} digitos`;
      }
    }
		  if (/[^0-9]/.test(fieldValue)) {
			  return `Caracteres inválidos en ${fieldName}`;
		  }
		  
		  return null;
	}

  const fixPhoneValidation = (fieldName, fieldValue) =>{
    if(fieldValue.trim() !== ''){
      if(fieldValue.length < 6 || fieldValue.length > 10){
        return `${fieldName} debe tener un tamaño entre 6 y 10 digitos`;
      }
    }
    if (/[^0-9]/.test(fieldValue)) {
      return `Caracteres inválidos en ${fieldName}`;
    }
		  
		  return null;
	}

  const emailValidation = (email) => {
    if(email){
      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email,
        )
      ) {
        return null;
      }		
      return 'Por favor ingresa un correo electrónico válido';  
    }
    return null;
	}

  const dateValidation = (nombreCampo, valor, requerido = false) => {
      if(!valor && requerido){
        return `${nombreCampo} es requerido`;
      }
      const dateValue = valor;
      const today = new Date();
      if(today.getTime() <= dateValue){
          return `${nombreCampo} no puede ser mayor a la fecha actual`;
      }
      return null;
  }

  const requiredValidation = (nombreCampo, valor) => {
    if(!valor){
      return `${nombreCampo} es requerido`;
    }
    if (valor.trim() === '') {
      return `${nombreCampo} es requerido`;
    }
    return null;
  }

  const dniValidation = (nombreCampo, valor) => {
      const validation = requiredValidation(nombreCampo, valor);
      if(validation)
        return validation;
      if (/[^a-zA-Z0-9]/.test(valor)) {
        return `Caracteres inválidos en ${nombreCampo}`;
      }
      
  }

  const validateChecked = (nombreCampo, valor, byPass = false) => {
    if(byPass){
      return null;
    }
    const validation = requiredValidation(nombreCampo, valor);
    if(validation){
      return validation;
    } 
    if(!valor){
      return `Debes aceptar ${nombreCampo} antes de realizar el registro.`;
    } 
    return null;    
}

  const validate = {
    nombres: name => nameValidation('Nombres', name),
    apellidos: name => nameValidation('Apellidos', name),
    celular: name => phoneValidation('Celular', name, 9),
    tipoIdentificacion: name => requiredValidation('Tipo de Identificación', name),
    numeroIdentificacion: name => dniValidation('Número de Identificación', name),
    correoElectronico: emailValidation,
    nacimiento: name => dateValidation('Fecha de Nacimiento', name, true),
    fechaEmision: name => dateValidation('Fecha de Emisión', name, true),
    genero: name => requiredValidation('Género', name),
    departamento: name => requiredValidation('Departamento', name),
    provincia: name => requiredValidation('Provincia', name),
    distrito: name => requiredValidation('Distrito', name),
    direccion: name => requiredValidation('Dirección', name),
    telefonoAdicional: name => fixPhoneValidation('Teléfono Adicional', name),
    aceptacionTerminosyPolitica: name => validateChecked('Términos y Politicas', name),    
    validacionSeguridad: name => validateChecked('Validacion Captcha', name, !enableRecaptcha),    
  };



  return(  
  <Layout customerSupporLink={urlWsp} >
    <SEO title="Inscripcion Asesoras" />
      <Row>
        <ContentBox loading={loading} content={boxContent} />
        <FormBox validate={validate} backgroundImage={bgImagen} initialValues={initialValues} />
      </Row>
  </Layout>
)}

export default IndexPage
