import React, { useEffect, useRef } from "react"
import { Col, Row } from "react-bootstrap";
import InscripcionForm from "./inscripcion-form/inscripcion-form"
import { gql, useMutation, useLazyQuery } from '@apollo/client';
 
const NUEVA_INSCRIPCION_MUTATION = gql`
mutation InscripcionNueva(
	$nombres: String!,
	$apellidos: String!,
	$celular: String!,
	$correoElectronico: String,
	$tipoIdentificacion: String!,
	$numeroIdentificacion: String!,
	$fechaEmision: DateTime!,
	$fechaCaducidad: DateTime,
	$nacimiento: DateTime!,
	$genero: String!,
	$departamento: String!,
	$provincia: String!,
	$distrito: String!,
	$otra: String,
	$direccion: String!,
	$complemento: String,
	$telefonoAdicional: String,
	$aceptacionTerminosCondiciones: String!,
	$aceptacionPoliticaPrivacidad: String!,
	$validacionSeguridad: String!,
  $verificaEmail: String!
) 
{ nuevaInscripcion( datosInscripcion: {
	nombres: $nombres, apellidos: $apellidos, celular: $celular,
	correoElectronico: $correoElectronico,	tipoIdentificacion: $tipoIdentificacion,
	numeroIdentificacion: $numeroIdentificacion, fechaEmision: $fechaEmision,
	fechaCaducidad: $fechaCaducidad, nacimiento: $nacimiento, genero: $genero,
	departamento: $departamento, provincia: $provincia, distrito: $distrito,
	otra: $otra, direccion: $direccion, complemento: $complemento, telefonoAdicional: $telefonoAdicional,
	aceptacionTerminosCondiciones: $aceptacionTerminosCondiciones,
	aceptacionPoliticaPrivacidad: $aceptacionPoliticaPrivacidad,
	validacionSeguridad: $validacionSeguridad, verificaEmail: $verificaEmail,
  }
)
}
`;

const GET_EMAIL_VALIDO = gql`
  query validarEmail($email: String!){
    emailValido(email: $email){
      resultado,
      razon
    }
  }
`;

const CONTENIDO_QUERY = gql`
query{
    referencias(tipo:"CONTENIDO"){
        nombre,
        valor
    }
}
`;

const FormBox = ({backgroundImage, initialValues, validate}) => {
    const [values, setValues] = React.useState(initialValues);
    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});
    const [invalidForm, setInvalidForm] = React.useState(true);
    const [mensajeExito, setMensajeExito] = React.useState('Pronto te contactaremos.');
    let backUrl = "https://files.loguin.com.co/static-force/images/bk_1.jpg";
    if(backgroundImage){
        backUrl = backgroundImage;
    }

    const formRef = useRef();

    const [validarEmail, {loading: verifingEmail}] = useLazyQuery(GET_EMAIL_VALIDO, {
      onError: (error) => {
        const name = 'correoElectronico';          
        const { [name]: removedError, ...rest } = errors;
        if(!verifingEmail && error){                    
          setValues({
            ...values,
            'verificaEmail': 'N',
          });            
        }else{
          const errorText = 'No se pudo validar el correo';
          setValues({
          ...values,
          'verificaEmail': 'N',
          });          
          setErrors({
            ...rest,
            ...(errorText && { [name]: touched[name] && errorText }),
          });
        }

      },
      onCompleted: (data) => {  
        const name = 'correoElectronico';          
        const { [name]: removedError, ...rest } = errors;
        let error = null;
        if(!verifingEmail && data){
          setInvalidForm(false);
          if(data.emailValido){
            if(data.emailValido.resultado === "undeliverable" || data.emailValido.resultado === "unknown"){    
              error = 'Debe proveer un correo válido';
              setInvalidForm(true);
            }else{
              setValues({
                ...values,
                'verificaEmail': 'S',
                });                
            }
          }               
          setErrors({
            ...rest,
            ...(error && { [name]: touched[name] && error }),
          });  
        }
      },
      fetchPolicy: "network-only"
    });

    const [loadMensajeExito] = useLazyQuery(CONTENIDO_QUERY, {
      onCompleted : (data) => {			
        const content = data.referencias.filter(referencia => referencia.nombre === 'TEXTO_EXITO_INSCRIPCION')
        setMensajeExito(content[0].valor);
      }
    });

    useEffect(() => {
      loadMensajeExito();
    }, [loadMensajeExito, setMensajeExito])

    const [nuevaInscripcion, { loading: isSavingInscripcion, error, data }] = useMutation(NUEVA_INSCRIPCION_MUTATION, {
      onCompleted : (data) => {
          if(data){
            if(!data.errors){
              setValues(initialValues);
              formRef.current.resetForm();
            }
          }          
      },
		variables: {
			nombres: values.nombres, 
			apellidos: values.apellidos, 
			celular: values.celular,
			correoElectronico: values.correoElectronico,	
			tipoIdentificacion: values.tipoIdentificacion,
			numeroIdentificacion: values.numeroIdentificacion, 
			fechaEmision: values.fechaEmision,
			fechaCaducidad: values.fechaCaducidad, nacimiento: values.nacimiento, 
			genero: values.genero,
			departamento: values.departamento, 
			provincia: values.provincia, 
			distrito: values.distrito,
			otra: values.otra, 
			direccion: values.direccion, 
			aceptacionTerminosCondiciones: values.aceptacionTerminosyPolitica,
			aceptacionPoliticaPrivacidad: values.aceptacionTerminosyPolitica,
			validacionSeguridad: values.validacionSeguridad
		  }
	  });
    
      
    const handleBlurEmail = (evt) => {        
      const { name, value } = evt.target;
      const { [name]: removedError, ...rest } = errors;
      if(validate[name]){
        const error = validate[name](value);
        if(!error){
          if(value){
            validarEmail({
              variables: {
                email: value
              }
            })  
          }
        }
        setErrors({
          ...rest,
          ...(error && { [name]: touched[name] && error }),
        });
      }     
      if(errors && Object.keys(errors).length === 0 && errors.constructor === Object){
        setInvalidForm(false);
      }
    }

    const handleChangeField = (name, newValue) => {       
      const value = newValue;        
      const { [name]: removedError, ...rest } = errors;
      setValues({
      ...values,
      [name]: value,
      });
      setTouched({
      ...touched,
      [name]: true,
      });
      if(validate[name]){
        const error = validate[name](value);
        setErrors({
          ...rest,
          ...(error && { [name]: touched[name] && error }),
        });
      }
      if(errors && Object.keys(errors).length === 0 && errors.constructor === Object){
        setInvalidForm(false);
      }
      
    }

    const handleChange = evt => {      
        const { name, value: newValue, type } = evt.target;
        const value = type === 'number' ? +newValue : newValue;
        setValues({
          ...values,
          [name]: value,
          });    
        setTouched({
          ...touched,
          [name]: true,
          });  
        if(errors && Object.keys(errors).length === 0 && errors.constructor === Object){
          setInvalidForm(false);
        }    
    };

    

    const handleBlur = evt => {
      const { name, value } = evt.target;
      console.log('handleBlurFB', name, value);
      const { [name]: removedError, ...rest } = errors;
      if(validate[name]){
        const error = validate[name](value);
        setErrors({
          ...rest,
          ...(error && { [name]: touched[name] && error }),
        });
        if(error){
          setValues({
            ...values,
            [name]: '',
            });  
        }
      }
      if(errors && Object.keys(errors).length === 0 && errors.constructor === Object){
        setInvalidForm(false);
      }
    };
  
    const handleSubmit = evt => {
      evt.preventDefault();
      const formValidation = Object.keys(values).reduce(
        (acc, key) => {
            const newTouched = { [key]: true };  
            if(validate[key]){
                const newError = validate[key](values[key]);
                const { [key]: removedError, ...rest } = acc.errors;
                return {
                    errors: {
                        ...rest,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched,
                    },
                };
            }else{
                return {
                    errors: {
                        ...acc.errors,
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched,
                    },
                }
            }
        },
        {
          errors: { ...errors },
          touched: { ...touched },
        },
      );    

      setErrors(formValidation.errors);
      setTouched(formValidation.touched);
      console.log(values);
      console.log(formValidation.touched)
      console.log(formValidation.errors)
      if (
        !Object.values(formValidation.errors).length &&
        Object.values(formValidation.touched).length ===
            Object.values(values).length &&
        Object.values(formValidation.touched).every(t => t === true)
      ) {
        if(!values['fechaCaducidad']){
          values['fechaCaducidad'] = null;
        }        
        nuevaInscripcion({
            variables: values
        })
        
      }
    };    
    return (
      <Col md={7} sm={12} className="form-container" style={{backgroundImage: `url(${backUrl})`}} >
        <Col md={12} >
                  <InscripcionForm ref={formRef}
                handleChangeField={handleChangeField}
                handleBlurEmail={handleBlurEmail}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
                values={values} 
                loadingSubmit={isSavingInscripcion}
                verifingEmail={verifingEmail}
                errorSubmit={error}
                dataSubmit={data}
                invalid={invalidForm}
                mensajeExito={mensajeExito}
                ></InscripcionForm>
          </Col>
      </Col>
    );
};

export default FormBox;