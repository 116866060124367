import  React  from 'react'
import { Form } from 'react-bootstrap';

import { Typeahead } from "react-bootstrap-typeahead";



const ListDivision = (props) => {
        const ref = React.createRef();
        let selected = [];
        if(props.selectedValue && props.selectedValue.trim() !== ''){
            if(props.data.length > 0){
                const selectedDivision = props.data.find(division => {
                    return division.nombre === props.selectedValue
                })
                if(selectedDivision){
                    selected.push(selectedDivision);
                }
            }
        }

        const onBlur = (evt) => {
            const value = evt.target.value;
            const selected = props.data.find((option) => {
                return option.nombre === value
            })
            if(!selected){
                ref.current.clear();
                evt.target.value = '';
            }
            props.onBlur(evt);
        }
            return (    <>
                <Typeahead
                    labelKey="nombre" 
                    ref={ref}
                    { ...props}                   
                    options={props.data}
                    inputProps={{ name: props.name, required: true}}
                    onBlur = {onBlur}
                    emptyLabel="No hay resultados"
                    selected = {selected}
                    >
                <Form.Control.Feedback type="invalid" className={props.errors ? 'visible-error' : ''} tooltip>
                    {props.errors}
                </Form.Control.Feedback>
                </Typeahead>
                </>
    );

}

export default ListDivision;