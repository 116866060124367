import React from 'react'
import { Button, Modal, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import './resultado-modal.css'

const ResultadoModal = (props) => {
  let initialTitle = '¡Tu solicitud ha sido enviada con éxito!';    
  if(props.type !== 'success'){
      initialTitle = '¡Tu solicitud no ha podido ser procesada!'
  }
  if(props.title){
    initialTitle = props.title;    
  }
  const handleClose = props.onHide;
  const CloseButton = () => {
      const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "icon_Close-Modal.png" }) {
          childImageSharp {
            fluid(maxWidth: 32) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

    if (!data?.placeholderImage?.childImageSharp?.fluid) {
      return <div>x</div>
    }
  
    return <Img style={{width: '32px'}} fluid={data.placeholderImage.childImageSharp.fluid} />
  
  }  
  return(
      <Modal {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          contentClassName='resultado-modal'
          dialogClassName='resultado-dialog'
          centered>
          <Modal.Body>      
            <Row className="row-btn-close">
              <Button variant="link" onClick={handleClose}>
                    <CloseButton />
                </Button>
              </Row>        
              <div className="modal-header" ><div className={`modal-title h4 ${props.type}`} >{initialTitle}</div></div>
              <div className="content">{props.children}</div>
              <div className="modal-footer"><Button variant="message-close" onClick={handleClose} >Aceptar</Button></div>
          </Modal.Body>
      </Modal>
  )
}

export default ResultadoModal;